<script lang="ts">
import { ref } from 'vue';
import { useRouter } from 'vue-router';

import { useRouterLinks } from '@/composables/navigation/useRouterLinks';
import { useProductCardRedesign } from '@/composables/useProductCardRedesign';
import { useSearch } from '@/composables/useSearch';
import { BuilderComponent } from '@/utils/cms';

// eslint-disable-next-line import/prefer-default-export
export const SearchFooterRegistration: BuilderComponent = {
  name: 'Search Footer',
  inputs: [
    {
      name: 'favoriteFlavors',
      type: 'list',
      defaultValue: [
        { label: 'Cinnamon', link: '/search/instant?query=cinnamon' },
        { label: 'Peanut', link: '/search/instant?query=peanut' },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
      ],
    },
    {
      name: 'popularSearches',
      type: 'list',
      defaultValue: [
        { label: 'Chocolate', link: '/search/instant?query=chocolate' },
        { label: 'Peanut Butter', link: '/search/instant?query=peanut butter' },
      ],
      subFields: [
        {
          name: 'label',
          type: 'string',
        },
        {
          name: 'link',
          type: 'string',
        },
      ],
    },
  ],
};
</script>

<script setup lang="ts">
defineProps<{
  favoriteFlavors: { label: string; link: string }[];
  popularSearches: { label: string; link: string }[];
}>();

const router = useRouter();

const searchQuery = ref('');
const search = async () => {
  await useProductCardRedesign().loadDyFlags();
  await useSearch(router).handleSearch(searchQuery);
};

const linksContainer = ref<HTMLElement>();
useRouterLinks(linksContainer);
</script>

<template>
  <div class="flex flex-col w-full h-auto">
    <div class="mb-4 text-xl font-semibold md:text-3xl font-sofia-pro">
      Search Your Favorite Snacks
    </div>
    <div class="relative">
      <form
        @submit.prevent="search"
        class="relative z-10 flex flex-col items-center bg-white border-black border-none rounded-lg md:flex-row md:border-solid md:border md:p-3 md:h-20 md:text-lg"
      >
        <input
          type="search"
          class="flex-1 w-full p-3 text-lg text-black bg-transparent border border-black border-solid rounded-lg outline-none md:mr-3 md:border-none"
          placeholder="Search by product or category"
          name="query"
          title="Search"
          aria-label="Search"
          v-model="searchQuery"
        />
        <input
          type="submit"
          class="box-border w-full p-3 mt-2 font-semibold border-none rounded-lg cursor-pointer md:w-auto bg-nuts-amber-400 md:mt-0 md:h-14 md:text-lg md:px-24"
          value="Search"
        />
      </form>
    </div>
    <div class="flex flex-col" ref="linksContainer">
      <div class="mb-0 text-xl font-semibold md:text-xl mt-7 md:mt-8 md:mb-3">Popular Searches</div>
      <div class="flex flex-wrap">
        <a
          v-for="item in popularSearches"
          :key="item.label"
          class="flex items-center px-4 py-6 mt-6 mr-2 text-sm font-semibold leading-none rounded-full bg-nuts-orange-50 md:px-10 md:py-4 h-7 md:h-12 hover:no-underline color-inherit md:text-base md:leading-none"
          :href="item.link"
        >
          {{ item.label }}
        </a>
      </div>
      <div class="mb-0 text-xl font-semibold md:text-xl mt-7 md:mt-8 md:mb-3">Favorite Flavors</div>
      <div class="flex flex-wrap">
        <a
          v-for="item in favoriteFlavors"
          :key="item.label"
          class="flex items-center px-4 py-6 mt-6 mr-2 text-sm font-semibold leading-none rounded-full bg-nuts-orange-50 md:px-10 md:py-4 h-7 md:h-12 hover:no-underline color-inherit md:text-base md:leading-none"
          :href="item.link"
        >
          {{ item.label }}
        </a>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
input[type='search']::-ms-clear {
  cursor: pointer;
}
input[type='search']::-ms-reveal {
  cursor: pointer;
}
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  cursor: pointer;
}
</style>
