/* eslint-disable import/prefer-default-export */
import { useFeatureFlags } from '@/composables/useFeatureFlags';

export function useProductCardRedesign() {
  async function loadDyFlags() {
    return useFeatureFlags().loadDyFlags('[A/B Test] Search and PLP Product Card Redesign', [
      'redesignedProductCardsV2',
      'redesignedProductCardsV3',
    ]);
  }

  return { loadDyFlags };
}
