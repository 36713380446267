<script lang="ts">
import { email, required } from '@vee-validate/rules';
import { useVModel } from '@vueuse/core';
import { Form } from 'vee-validate';
import { computed, defineComponent, PropType, ref } from 'vue';
import { useStore } from 'vuex';

import EmailTypoSuggestion from '@/components/base/form/EmailTypoSuggestion.vue';
import FormInput, { InputAttributes } from '@/components/base/form/FormInput.vue';
import GlobalErrorNotification from '@/components/base/GlobalErrorNotification.vue';
import RouteLink from '@/components/base/RouteLink.vue';
import SeparatorWithText from '@/components/base/SeparatorWithText.vue';
import ThemedButton from '@/components/base/ThemedButton.vue';
import ContinueAsGuestButton from '@/components/login/ContinueAsGuestButton.vue';
import AppleSignInButton from '@/components/social/AppleSignInButton.vue';
import Captcha, { CaptchaPublicInstance } from '@/components/social/Captcha.vue';
import FacebookSignInButton from '@/components/social/FacebookSignInButton.vue';
import GoogleSignInButton from '@/components/social/GoogleSignInButton.vue';
import { UseCallback } from '@/composables/useCallback';
import { useCart } from '@/composables/useCart';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { FlexibleMessageValidator, useForm } from '@/composables/useForm';

interface EmailStepData {
  email: string;
}

export default defineComponent({
  name: 'EmailStep',
  props: {
    isCheckout: { required: false, type: Boolean, default: false },
    modelValue: { required: true, type: String },
    submitCallback: {
      required: true,
      type: Object as PropType<UseCallback<any, [string]>>,
    },
  },
  components: {
    AppleSignInButton,
    Captcha,
    ContinueAsGuestButton,
    EmailTypoSuggestion,
    FacebookSignInButton,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    FormInput,
    GlobalErrorNotification,
    GoogleSignInButton,
    RouteLink,
    SeparatorWithText,
    ThemedButton,
  },
  emits: {
    'change-email': (_: string) => true,
    'social-sign-in': null,
    'update:modelValue': () => true,
  },
  setup(props, { emit }) {
    const store = useStore();
    const { containsAutoDelivery } = useCart(store);
    const { flags } = useFeatureFlags();

    const emailValue = useVModel(props, 'modelValue', emit);
    const { errorMessages, validatorFailed } = useForm();

    const captcha = ref<CaptchaPublicInstance>();
    const isCaptchaPending = ref(false);

    const isLoading = computed(() => isCaptchaPending.value || props.submitCallback.isPending);
    const showGoogleSignIn = computed(() => {
      if (typeof window !== 'undefined') {
        return !window?.navigator.userAgent.includes('Instagram');
      }
      return true;
    });

    const formInputAttributes: Record<keyof EmailStepData, InputAttributes> = {
      email: {
        autocomplete: 'username',
        name: 'email',
        placeholder: 'Email',
        type: 'email',
      },
    };

    const rules: Record<keyof EmailStepData, FlexibleMessageValidator<string>> = {
      email: (value) => {
        if (!required(value)) return errorMessages.email.required;
        if (!email(value)) return errorMessages.email.email;
        return true;
      },
    };

    const onSubmit = () => {
      captcha.value?.reset();
      isCaptchaPending.value = true;
      captcha.value?.execute();
    };

    const onCaptchaVerification = (response: string) => {
      props.submitCallback.execute(response);
      isCaptchaPending.value = false;
    };

    return {
      captcha,
      changeEmail: () => emit('change-email', ''),
      containsAutoDelivery,
      emailValue,
      flags,
      formInputAttributes,
      isLoading,
      onCaptchaVerification,
      onSocialSignIn: () => emit('social-sign-in'),
      onSubmit,
      rules,
      showGoogleSignIn,
      validatorFailed,
    };
  },
});
</script>

<template>
  <div :class="{ 'opacity-50 pointer-events-none': isLoading }">
    <div v-if="isCheckout && !containsAutoDelivery">
      <ContinueAsGuestButton class="mt-6" :email="emailValue" />
    </div>
    <div v-else class="inline-flex items-center mt-6">
      <p class="text-base font-semibold">Don't have an account?</p>
      <button
        class="p-0 ml-1 bg-transparent border-none cursor-pointer"
        @click="changeEmail"
        data-test="sign-up-link"
      >
        <span class="text-sm font-semibold underline text-cyan-700 font-proxima-nova sm:text-base">
          Sign up
        </span>
      </button>
    </div>
    <p v-if="isCheckout && containsAutoDelivery" class="mt-2 text-base">
      Auto-Delivery items are not eligible for guest checkout. Create an account and apply your
      preferred payment method to proceed.
    </p>
    <SeparatorWithText class="mt-6" data-test="sign-in-with-email-text">
      Sign in or sign up with email
    </SeparatorWithText>
    <div class="relative mt-6">
      <Form v-slot="{ meta: formEmailMeta }" @submit="onSubmit">
        <FormInput
          v-model="emailValue"
          :inputAttributes="formInputAttributes.email"
          showLabel
          :validator="rules.email"
          dataTest="sign-in"
        />
        <EmailTypoSuggestion v-model="emailValue" />
        <transition name="fade">
          <GlobalErrorNotification v-if="submitCallback.error" class="mt-4 md:mt-8" />
        </transition>
        <ThemedButton
          type="submit"
          class="w-full px-8 py-4 mt-8 cursor-pointer h-11 sm:h-12"
          :isLoading="isLoading"
          theme="gray"
          :disabled="(formEmailMeta.touched && validatorFailed(formEmailMeta)) ?? false"
          data-test="continue-button"
        >
          Continue
        </ThemedButton>
      </Form>
    </div>
    <SeparatorWithText class="mt-6" data-test="sign-in-with-social-account-text"
      >Sign in or sign up with social account</SeparatorWithText
    >
    <div class="flex flex-col mt-6 gap-y-4">
      <GoogleSignInButton
        v-if="showGoogleSignIn"
        fullWidth
        :isCheckout="isCheckout"
        @signed-in="onSocialSignIn"
      />
      <FacebookSignInButton fullWidth :isCheckout="isCheckout" @signed-in="onSocialSignIn" />
      <AppleSignInButton fullWidth :isCheckout="isCheckout" @signed-in="onSocialSignIn" />
    </div>
    <p class="mt-6 text-xs leading-5 sm:text-sm">
      By clicking on Continue with{{ showGoogleSignIn ? ' Google,' : '' }} Facebook or Apple, you
      represent that you are 18+ years of age and have read and agreed to the Nuts.com
      <RouteLink class="underline" to="/terms-and-conditions">Terms &amp; Conditions</RouteLink>,
      <RouteLink class="underline" to="/privacy">Privacy Policy</RouteLink>
      and
      <RouteLink class="underline" to="/california-privacy-notice">CA Privacy Notice</RouteLink>.
      Nuts.com may send you communications. You may change your preferences in your account
      preferences at any time.
    </p>
    <Captcha ref="captcha" @verify="onCaptchaVerification" />
  </div>
</template>

<style lang="scss" scoped>
p {
  @apply mb-0;
}
</style>
