<script setup lang="ts">
import Header6 from '@/components/base/typography/Header6.vue';
import { useFeatureFlags } from '@/composables/useFeatureFlags';
import { LargeMenu } from '@/utils/navMenu';

const props = defineProps<{
  activeState?: 'active' | 'suppressed';
  header: LargeMenu['header'];
}>();

const { flags } = useFeatureFlags();
</script>

<template>
  <div class="flex items-center" :class="{ 'opacity-50': activeState === 'suppressed' }">
    <img v-if="header.icon" class="w-5 h-5" alt="featured" :src="header.icon" />
    <Header6
      :class="{
        'text-nuts-orange-50': flags.layoutRebrandingChangesV1,
        'hover:underline decoration-nuts-amber-400 decoration-4 underline-offset-8':
          !flags.layoutRebrandingChangesV1,
        underline: props.activeState === 'active' && !flags.layoutRebrandingChangesV1,
      }"
      :style="{ color: flags.layoutRebrandingChangesV1 ? undefined : header.color }"
    >
      {{ header.text }}
      <span class="sr-only">Press arrowdown key to enter submenu</span>
    </Header6>
  </div>
</template>
