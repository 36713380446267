<script setup lang="ts">
import { Subscription } from '@nuts/auto-delivery-sdk';
import { ref } from 'vue';

import AddedToSubscriptionActions from '@/components/auto-delivery/added-to-auto-delivery/AddedSubscriptionControls.vue';
import AddToCartOrCustomizePresentational from '@/components/base/add-to-cart/AddToCartOrCustomizePresentational.vue';
import { useCallback } from '@/composables/useCallback';
import { getProposedDeliveryInterval } from '@/lib/autoDelivery';
import { useAutoDelivery } from '@/stores/autoDelivery';
import { BuiltProposedDelivery, getDeliverySubscriptionBySku } from '@/utils/autoDelivery';
import { ProductCardData } from '@/utils/productCard';

const props = defineProps<{
  delivery: BuiltProposedDelivery;
  mode: 'one-time' | 'recurring';
  product: ProductCardData;
}>();

const emit = defineEmits<{
  added: [];
  removed: [];
}>();

const addedSubscription = ref<Subscription>();

const autoDeliveryStore = useAutoDelivery();

async function refreshOrders() {
  await autoDeliveryStore.getOrders({
    preloadPaymentMethod: false,
    preloadShippingAddresses: false,
  });
}

const onAddToAutoDelivery = useCallback(async () => {
  await autoDeliveryStore.createSubscription({
    active: true,
    interval:
      props.mode === 'recurring' ? getProposedDeliveryInterval(props.delivery, 'days') : undefined,
    nextShipOn: props.delivery.shipOn,
    offerLocation: 'Added to Auto-Delivery Modal',
    offerType: props.mode === 'recurring' ? 'IU Recurring' : 'IU Upsell',
    quantity: 1,
    shippingAddressId: props.delivery.shippingAddressId,
    sku: props.product.sku,
  });
  await refreshOrders();
  addedSubscription.value = getDeliverySubscriptionBySku(props.delivery, props.product.sku);
  emit('added');
});

const handleRemove = useCallback(async () => {
  if (!addedSubscription.value) return;
  await autoDeliveryStore.cancelSubscription(
    addedSubscription.value,
    'Removed with remove button',
    undefined,
    false,
  );
  await refreshOrders();
  addedSubscription.value = undefined;
  emit('removed');
});
</script>

<template>
  <Transition class="w-full" mode="out-in" name="fade">
    <AddedToSubscriptionActions
      v-if="addedSubscription"
      :delivery
      :isLoading="handleRemove.isPending"
      :subscription="addedSubscription"
      @remove="handleRemove.execute"
    />
    <AddToCartOrCustomizePresentational
      v-else-if="product.autoDeliveryEligible"
      class="gap-1 px-4"
      fullWidth
      :isLoading="onAddToAutoDelivery.isPending"
      theme="blue"
      @add-to-cart="onAddToAutoDelivery.execute"
    >
      <template #add-to-cart-text>
        <template v-if="onAddToAutoDelivery.isPending">Adding</template>
        <template v-else-if="mode === 'recurring'">
          Add to
          <img
            alt="Auto delivery"
            class="h-3.5 w-20 mt-0.5"
            src="@/assets/auto-delivery/logo-white.svg"
          />
        </template>
        <template v-else>Ship One-Time</template>
      </template>
    </AddToCartOrCustomizePresentational>
  </Transition>
</template>
