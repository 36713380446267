<script lang="ts">
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import SearchBarStaticContent from '@/components/layout/header/SearchBarStaticContent.vue';
import { useProductCardRedesign } from '@/composables/useProductCardRedesign';
import { useSearch } from '@/composables/useSearch';
import { CmsRegisteredComponent } from '@/utils/cms';

const SearchMobile = defineComponent({
  name: 'SearchMobile',
  components: {
    SearchBarStaticContent,
  },
  setup() {
    const router = useRouter();

    const searchQuery = ref('');

    const search = async () => {
      await useProductCardRedesign().loadDyFlags();
      await useSearch(router).handleSearch(searchQuery);
    };

    return {
      search,
      searchQuery,
    };
  },
});

export const SearchMobileRegistration: CmsRegisteredComponent = {
  component: SearchMobile,
  name: 'Mobile Search Bar',
};

export default SearchMobile;
</script>

<template>
  <div class="visible-xs section masthead-alternative">
    <div class="ptn-search-box">
      <form class="relative ws-sm:static" @submit.prevent="search">
        <input
          type="search"
          class="search-field"
          data-test="search-field-mobile"
          placeholder="Search for a product"
          name="query"
          title="Search"
          aria-label="Search"
          v-model="searchQuery"
        />
        <SearchBarStaticContent />
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.search-field {
  border-radius: 4px;
  padding: 12px 100px 12px 12px;
  height: 39px;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid $eastern-blue;
}
</style>
