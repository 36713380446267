/* eslint-disable import/prefer-default-export */
import { MaybeRefOrGetter, toValue } from 'vue';
import { Router } from 'vue-router';

import { useRouteChange } from '@/composables/navigation/useRouteChange';

export function useSearch(router?: Router) {
  const { navigateTo } = useRouteChange(router);

  async function handleSearch(query: MaybeRefOrGetter<string | undefined>) {
    const value = toValue(query);
    let href = '/search/instant';
    if (value) href += `?query=${value}`;
    await navigateTo(href);
  }

  return { handleSearch };
}
