import { Order, TypedMoney } from '@commercetools/platform-sdk';
import { dollars } from '@nuts/auto-delivery-sdk/dist/utils/money';
import type { IsEqual } from 'type-fest/source/internal';

import type { PaymentItem } from '@/composables/usePayment';
import { NutsLineItem } from '@/lib/cart/lineItem';
import {
  CartViewedProductsItem,
  CheckoutStartedProductsItem,
  OrderCompleted,
  OrderCompletedProductsItem,
  ProductAdded,
  ProductClicked,
  productClicked,
  ProductRemoved,
  productsSearched,
} from '@/rudder-typer';
import { getUnitName } from '@/utils/product';
import { ProductCardData } from '@/utils/productCard';
import { reportError } from '@/utils/reportError';

export interface GoogleEventObjItem {
  coupon?: string;
  index?: number;
  item_id: string;
  item_name?: string;
  item_variant?: string;
  merchandising_category?: string;
  price: number;
  quantity?: number;
}

export interface GoogleEventObj {
  items: GoogleEventObjItem[];
}

export function buildProductAddedPayload(
  googleEventObj: Record<string, unknown>,
  additionalData: { cartId?: string; url?: string; imageUrl?: string },
): ProductAdded | undefined {
  let result: ProductAdded | undefined;

  if (googleEventObj.items && Array.isArray(googleEventObj.items)) {
    const [item] = googleEventObj.items;

    let { price } = item;
    if (typeof price === 'string') {
      price = Number(price[0] === '$' ? price.slice(1) : price);
    }

    result = {
      cart_id: additionalData.cartId,
      category: item.merchandising_category,
      coupon: item.coupon,
      image_url: additionalData.imageUrl,
      name: item.item_name,
      position: item.index,
      price,
      product_id: item.item_id,
      quantity: item.quantity,
      reporting_category: item.reporting_category,
      sku: item.item_variant,
      url: additionalData.url,
      variant: item.item_variant_name,
    };
  }

  return result;
}

export type StandardProductsItem = CartViewedProductsItem;

// assert that various cart/checkout-related RS *ProductsItem types are identical
type StaticAssert<T extends true> = T;
type _check = StaticAssert<IsEqual<StandardProductsItem, CartViewedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, CheckoutStartedProductsItem>> &
  StaticAssert<IsEqual<StandardProductsItem, OrderCompletedProductsItem>>;

export const formatStandardProductsItem = (lineItem: NutsLineItem): StandardProductsItem => ({
  category: lineItem.variant.attributes?.find((attr) => attr.name === 'merchandisingCategory')
    ?.value.key,
  image_url: lineItem.titleImage?.url,
  name: lineItem.name?.en,
  price: dollars(lineItem.piecePrice),
  product_id: lineItem.productKey,
  quantity: lineItem.quantity,
  reporting_category: lineItem.variant.attributes?.find((attr) => attr.name === 'reportingCategory')
    ?.value.key,
  sku: lineItem.variant.sku,
  url: lineItem.productPath,
  variant: getUnitName(lineItem.variant.variantName) ?? undefined,
});

export const formatOrderCompleted = (context: {
  cartId?: string;
  estimatedTotal?: TypedMoney;
  order: Order;
  paymentItems: PaymentItem[];
}): OrderCompleted => {
  const { cartId, estimatedTotal, order, paymentItems } = context;

  const discount = paymentItems.find((item) => item.label === 'Discount');
  const shipping = paymentItems.find((item) => item.label === 'Shipping');
  const subtotal = paymentItems.find((item) => item.label === 'Subtotal');
  const tax = paymentItems.find((item) => item.label === 'Tax');

  let coupon = '';
  const { discountCodes } = order;
  if (discountCodes) {
    coupon = discountCodes[0]?.discountCode.obj?.name?.en ?? '';
  }

  const products = order.lineItems.map(NutsLineItem.fromCt).map(formatStandardProductsItem);

  return {
    checkout_id: cartId,
    coupon,
    currency: 'USD',
    discount: dollars(discount?.amount) || 0,
    order_id: order.orderNumber!, // We are confident that placeOrder always sets this
    products,
    revenue: dollars(order.taxedPrice?.totalGross),
    shipping: shipping && dollars(shipping?.amount),
    subtotal: subtotal && dollars(subtotal?.amount),
    tax: tax && dollars(tax?.amount),
    total: estimatedTotal && dollars(estimatedTotal),
  };
};

export const formatProductRemoved = (lineItem: NutsLineItem, cartId: string): ProductRemoved => ({
  ...formatStandardProductsItem(lineItem),
  cart_id: cartId,
});

export const formatProductClicked = (
  productCard: ProductCardData,
  position?: number,
): ProductClicked => ({
  category: productCard.merchandisingCategory,
  coupon: productCard.totalSavings?.description?.en,
  name: productCard.name,
  position,
  price: dollars(productCard.piecePrice),
  product_id: productCard.productKey,
  quantity: 1,
  reporting_category: productCard.reportingCategory,
  sku: productCard.sku,
  variant: productCard.unitName,
});

export const sendProductClickedEvent = (productCard: ProductCardData, position?: number): void => {
  try {
    const payload = formatProductClicked(productCard, position);
    productClicked(payload);
  } catch (error) {
    reportError(error);
  }
};

export const sendProductsSearchedEvent = (query: string): void => {
  try {
    if (query) {
      productsSearched({ query });
    }
  } catch (error) {
    reportError(error);
  }
};
